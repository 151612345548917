<template>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal",}'>
            <thead>
                <tr>
                    <td
                        :style='{...estilos.tablaBorde}'
                    >
                    </td>
                    <td
                        v-for="hora in $store.state.enfermeria.horarios[turno]"
                        :key="hora"
                        width="4%"
                        :style='{...estilos.tablaBorde}'
                    >
                        <div :style="{'text-align': 'center'}">{{hora.split(':')[0]}}</div>
                    </td>
                    <td
                        :style='{...estilos.tablaBorde}'
                        width="4%"
                    >
                        <div :style="{'text-align': 'center'}">Total</div>
                    </td>
                </tr>
                <tr>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3", "padding":"0px 3px 0px 30px", "letter-spacing": "10px", "font-weight":"900"}}'>
                        <b>INGRESOS</b>
                    </td>
                    <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}' :colspan=2>
                        <div :style='{"font-size":"8pt", "text-align": "right"}'>
                            {{ $numeral(datos.totalIngresos).format('0,0.00') }}
                        </div>
                    </td>
                </tr>
            </thead>

            <tbody
                v-for="item in ingresosItemsList"
                :key="item"
                :style='{"font-size":"7pt", "border-collapse": "collapse", "line-height": "normal",}'
                >
                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"text-transform": "uppercase"}, ...{"text-align":"center"}}' :colspan='$store.state.enfermeria.horarios[turno].length + 2'>
                        <b>{{item}}</b>
                    </td>
                </tr>
                <tr
                    v-for="entrada in filtraEntradas(item, 'ing')"
                    :key="entrada.id"
                >
                    <td
                        :style='{...estilos.tablaBorde, ...{"padding":"0px 3px 0px 3px"}}'
                    >
                        <span>{{entrada}}</span>
                    </td>
                    <td
                        v-for="itm in $store.state.enfermeria.horarios[turno]"
                        :key="itm.id"
                        :style='{...estilos.tablaBorde}'
                    >
                        <div
                            v-for="liq in totalRegistroEntrada(entrada, itm, 'ing')"
                            :key="liq.id"
                            @click="abreDetalle(liq)"
                        >
                            <div :style='{"font-size":"6pt", "text-align": "right"}'>{{$numeral(liq.cantidad).format('0,0.00')}}</div>
                        </div>
                    </td>
                    <td
                        :style='{...estilos.tablaBorde}'
                    >
                        <div :style='{"font-size":"6pt", "text-align": "right"}'>{{ $numeral(totalRow(entrada, 'ing')).format('0,0.00') }}</div>
                    </td>
                </tr>
                <tr :style='{"text-transform": "uppercase"}'>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"text-align":"right"}}'>
                        <b>Total {{item}}</b>
                    </td>
                    <td :style='{...estilos.tablaBorde}' :colspan=2>
                        <div :style='{"font-size":"8pt", "text-align": "right"}'>
                            {{ $numeral(totalCat(item, 'ing')).format('0,0.00') }}
                        </div>
                    </td>
                </tr>
            </tbody>
            <!-- --------------------------------------------------------------------------- -->

            <thead>
                <tr >
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3", "padding":"0px 3px 0px 30px", "letter-spacing": "10px", "font-weight":"900"}}'>
                        <b>EGRESOS</b>
                    </td>
                    <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}' :colspan=2>
                        <div :style='{"font-size":"8pt", "text-align": "right"}'>
                            {{ $numeral(datos.totalEgresos).format('0,0.00') }}
                        </div>
                    </td>
                </tr>
            </thead>

            <tbody
                v-for="item in egresosItemsList"
                :key="item"
                :style='{"font-size":"7pt", "border-collapse": "collapse", "line-height": "normal",}'
                >
                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"text-transform": "uppercase"}, ...{"text-align":"center"}} ' :colspan='$store.state.enfermeria.horarios[turno].length + 2'>
                        <b>{{item}}</b>
                    </td>
                </tr>
                <tr
                    v-for="entrada in filtraEntradas(item, 'egr')"
                    :key="entrada.id"
                >
                    <td
                        :style='{...estilos.tablaBorde, ...{"padding":"0px 3px 0px 3px"}}'
                    >
                        <span>{{entrada}}</span>
                    </td>
                    <td
                        v-for="itm in $store.state.enfermeria.horarios[turno]"
                        :key="itm.id"
                        :style='{...estilos.tablaBorde}'
                    >
                        <div
                            v-for="liq in totalRegistroEntrada(entrada, itm, 'egr')"
                            :key="liq.id"
                        >
                            <div :style='{"font-size":"6pt", "text-align": "right"}'>{{$numeral(liq.cantidad).format('0,0.00')}}</div>
                        </div>
                    </td>
                    <td
                        :style='{...estilos.tablaBorde}'
                    >
                        <div :style='{"font-size":"6pt", "text-align": "right"}'>{{ $numeral(totalRow(entrada, 'egr')).format('0,0.00') }}</div>
                    </td>
                </tr>
                <tr :style='{"text-transform": "uppercase"}'>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"text-align":"right"}}'>
                        <b>Total {{item}}</b>
                    </td>
                    <td :style='{...estilos.tablaBorde}' :colspan=2>
                        <div :style='{"font-size":"8pt", "text-align": "right"}'>
                            {{ $numeral(totalCat(item, 'egr')).format('0,0.00') }}
                        </div>
                    </td>
                </tr>
            </tbody>
                <tr v-if="datos.fecal.length>0">
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde}'>
                        <span ><b>GASTO FECAL</b></span>
                    </td>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                        <div v-if="datos.fecal.length>0">
                            {{datos.fecal.length>0?$numeral(datos.fecal[0].cantidad).format('0,0.00'):'-'}}
                        </div>
                    </td>
                </tr>

                <tr v-if="datos.urinario.length>0">
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde}'>
                        <span><b>GASTO URINARIO</b></span>
                    </td>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                        <div v-if="datos.urinario.length>0">
                            {{datos.urinario.length>0?$numeral(datos.urinario[0].cantidad).format('0,0.00'):'-'}}
                        </div>
                    </td>
                </tr>
            <tbody>
                <tr>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length - 4' :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3", "padding":"0px 3px 0px 30px", "font-weight":"900"}}'>
                        <span><b>BALANCE TOTAL (al turno)</b></span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right", "background-color": $store.state.enfermeria.coloresTuro["mat"]}}'>
                            Mat.
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right", "background-color": $store.state.enfermeria.coloresTuro["ves"]}}'>
                            Ves
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right", "background-color": $store.state.enfermeria.coloresTuro["noc"]}}'>
                            Noc.
                    </td>
                </tr>
                <tr>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length - 4' :style='{...estilos.tablaBorde, ...{"padding":"0px 5px 0px 5px"}}'>
                        <span><b>INGRESOS</b></span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                            <span>{{$numeral(datos.todosTurnos.mati).format('0,0.00')}}</span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                            <span v-if="turno=='ves' || turno=='noc' ">{{$numeral(datos.todosTurnos.vesi).format('0,0.00')}}</span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                            <span v-if="turno=='noc'" >{{$numeral(datos.todosTurnos.noci).format('0,0.00')}}</span>
                    </td>
                </tr>
                <tr>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length - 4' :style='{...estilos.tablaBorde, ...{"padding":"0px 5px 0px 5px"}}'>
                        <span><b>EGRESOS</b></span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                            <span>{{$numeral(datos.todosTurnos.mate).format('0,0.00')}}</span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                            <span v-if="turno=='ves' || turno=='noc' ">{{$numeral(datos.todosTurnos.vese).format('0,0.00')}}</span>
                    </td>
                    <td colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                            <span  v-if="turno=='noc'" >{{$numeral(datos.todosTurnos.noce).format('0,0.00')}}</span>
                    </td>
                </tr>
                <tr>
                    <td>.</td>
                </tr>
                <tr>
                    <td :colspan='$store.state.enfermeria.horarios[turno].length' :style='{...estilos.tablaBorde, ...{"padding":"0px 5px 0px 5px"}}'>
                        <span><b>GRAN TOTAL AL TURNO</b></span>
                    </td>
                    <td v-if="turno=='mat'" colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                        <span>{{$numeral(datos.todosTurnos.mati - datos.todosTurnos.mate).format('0,0.00')}}</span>
                    </td>
                    <td v-if="turno=='ves'" colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                        <span>{{$numeral((datos.todosTurnos.mati + datos.todosTurnos.vesi)-(datos.todosTurnos.mate + datos.todosTurnos.vese)).format('0,0.00')}}</span>
                    </td>
                    <td v-if="turno=='noc'" colspan="2" :style='{...estilos.tablaBorde, ...{"font-size":"8pt", "text-align": "right"}}'>
                        <span>{{$numeral((datos.todosTurnos.mati + datos.todosTurnos.vesi + datos.todosTurnos.noci)-(datos.todosTurnos.mate + datos.todosTurnos.vese + datos.todosTurnos.noce)).format('0,0.00')}}</span>
                    </td>
                </tr>
            </tbody>
        </table>

</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

    methods:{
        filtraEntradas(item, tipo){
            let salida
            if(tipo=="ing"){
                salida = this.datos.ingresos.filter(ent=>ent.itemNombre==item)
                salida = [... new Set(salida.map(a=>a.observaciones))]
            }
            if(tipo=="egr"){
                salida = this.datos.egresos.filter(ent=>ent.itemNombre==item)
                salida = [... new Set(salida.map(a=>a.itemNombre))]
            }
            return salida
        },
        totalRegistroEntrada(txt, hora, tipo){
            // obtiene la hora
            let salida
            let soloHora = hora.split(':')[0]
            // obtiene los registros con la cadena
            if (tipo == 'ing'){
                salida = this.datos.ingresos.filter(ent=>ent.observaciones==txt)
            }
            if (tipo == 'egr'){
                salida = this.datos.egresos.filter(ent=>ent.itemNombre==txt)
            }
            salida = salida.filter(ent=>ent.hora.split(':')[0] == soloHora)
            return salida
        },
        totalRow(txt, tipo){
            let salida
            let valor = 0

            if (tipo == 'ing'){
                salida = this.datos.ingresos.filter(ent=>ent.observaciones==txt)
            }

            if (tipo=='egr'){
                salida = this.datos.egresos.filter(ent=>ent.itemNombre==txt)
            }
            valor = salida.map(itm=>itm.cantidad)
            return valor.reduce((a, b) => a + b, 0)
        },
        totalCat(txt, tipo){
            let valor = 0
            let salida
            if (tipo == 'ing'){
                salida = this.datos.ingresos.filter(ent=>ent.itemNombre==txt)
            }

            if (tipo=='egr'){
                salida = this.datos.egresos.filter(ent=>ent.itemNombre==txt)
            }
            valor = salida.map(itm=>itm.cantidad).reduce((a, b) => a + b, 0)
            return valor


        },
    },

    computed:{
        ingresosItemsList(){
            return [...new Set(this.datos.ingresos.map(a => a.itemNombre))].sort()
        },
        egresosItemsList(){
            return [...new Set(this.datos.egresos.map(a => a.itemNombre))].sort()
        },
        
    },

}
</script>

<style>

</style>